var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-user-app" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible,
          expression: "dialogVisible"
        }
      ],
      staticClass: "add-user-app__overlay",
      on: {
        click: function($event) {
          _vm.close()
        }
      }
    }),
    _vm._v(" "),
    _vm.dialogVisible
      ? _c("div", { staticClass: "add-user-app__dialog" }, [
          _c("h2", [_vm._v("Benutzer hinzufügen")]),
          _vm._v(" "),
          typeof _vm.error === "string"
            ? _c("p", { staticClass: "alert alert--error" }, [
                _vm._v("\n            " + _vm._s(_vm.error) + "\n        ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "form",
              attrs: { method: "post" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  _vm.submit()
                }
              }
            },
            [
              _c("div", { staticClass: "grid-x grid-padding-x" }, [
                _c(
                  "div",
                  {
                    staticClass: "control-group cell medium-6",
                    class: _vm.hasError("first_name")
                      ? "control-group--error"
                      : ""
                  },
                  [
                    _c("label", [_vm._v("Vorname:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        ref: "firstname",
                        attrs: { type: "text", disabled: _vm.loading }
                      }),
                      _vm._v(" "),
                      _vm.hasError("first_name")
                        ? _c(
                            "span",
                            { staticClass: "help-inline help-inline--error" },
                            [_vm._v(_vm._s(_vm.error.first_name[0]))]
                          )
                        : _vm._e()
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "control-group cell medium-6",
                    class: _vm.hasError("last_name")
                      ? "control-group--error"
                      : ""
                  },
                  [
                    _c("label", [_vm._v("Nachname:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        ref: "lastname",
                        attrs: { type: "text", disabled: _vm.loading }
                      }),
                      _vm._v(" "),
                      _vm.hasError("last_name")
                        ? _c(
                            "span",
                            { staticClass: "help-inline help-inline--error" },
                            [_vm._v(_vm._s(_vm.error.last_name[0]))]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-x grid-padding-x" }, [
                _c(
                  "div",
                  {
                    staticClass: "control-group cell medium-6",
                    class: _vm.hasError("name_prefix")
                      ? "control-group--error"
                      : ""
                  },
                  [
                    _c("label", [_vm._v("Namenspräfix:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        ref: "nameprefix",
                        attrs: { type: "text", disabled: _vm.loading }
                      }),
                      _vm._v(" "),
                      _vm.hasError("name_prefix")
                        ? _c(
                            "span",
                            { staticClass: "help-inline help-inline--error" },
                            [_vm._v(_vm._s(_vm.error.first_name[0]))]
                          )
                        : _vm._e()
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "control-group cell medium-6",
                    class: _vm.hasError("name_suffix")
                      ? "control-group--error"
                      : ""
                  },
                  [
                    _c("label", [_vm._v("Namenssuffix:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        ref: "namesuffix",
                        attrs: { type: "text", disabled: _vm.loading }
                      }),
                      _vm._v(" "),
                      _vm.hasError("name_suffix")
                        ? _c(
                            "span",
                            { staticClass: "help-inline help-inline--error" },
                            [_vm._v(_vm._s(_vm.error.last_name[0]))]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-x grid-padding-x" }, [
                _c(
                  "div",
                  {
                    staticClass: "control-group cell medium-12",
                    class: _vm.hasError("username")
                      ? "control-group--error"
                      : ""
                  },
                  [
                    _c("label", [_vm._v("E-Mail:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        ref: "email",
                        attrs: { type: "text", disabled: _vm.loading }
                      }),
                      _vm._v(" "),
                      _vm.hasError("username")
                        ? _c(
                            "span",
                            { staticClass: "help-inline help-inline--error" },
                            [_vm._v(_vm._s(_vm.error.username[0]))]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form__actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    attrs: { type: "submit" }
                  },
                  [
                    _vm.loading
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        ])
                      : _vm._e(),
                    _vm._v(" Benutzer hinzufügen")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button button--cancel",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        _vm.close()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                )
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "button button--primary",
        attrs: { href: "#" },
        on: {
          click: function($event) {
            _vm.showDialog()
          }
        }
      },
      [_vm._v("Benutzer hinzufügen")]
    ),
    _vm._v(" "),
    _c(
      "a",
      { staticClass: "button button--primary", attrs: { href: "csv-import/" } },
      [_vm._v("Benutzer via CSV importieren")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-50995e4a", { render: render, staticRenderFns: staticRenderFns })
  }
}