import Vue from 'vue';
import datetime from 'vuejs-datetimepicker';
import AddUserApp from './components/add_user_app.vue';

Vue.directive('focus', {
    inserted: (el) => {
        el.focus();
    }
});

function overlayDeadline() {
    const el = document.getElementById('id_deadline');
    if (!el) {
        return;
    }

    new Vue({
        el: '#id_deadline',
        components: {
            datetime
        },
        data() {
            return {
                date: el.value,
            };
        },
        template: `<datetime format="YYYY-MM-DD H:i:s" firstDayOfWeek="1" v-model="date" name="deadline">
        </datetime>`
    });
}

function registerAddUserApp() {
    const el = document.getElementById('add-user-app');
    const clientID = window.location.href.split('/')[4];
    if (!el) {
        return;
    }
    new Vue({
        components: {
            AddUserApp
        },
        el,
        template: `<AddUserApp clientID="${clientID}" />`
    })
}

function registerVideoHandlers() {
    let timeout = null;
    let disabled = false;
    const sleepMS = 800;
    const videos = document.querySelectorAll('video');

    if (!videos.length) {
        return;
    }

    const progressButtons = document.querySelectorAll('.button.button--progress');
    const progressInputs = document.querySelectorAll('.checkbox--progress');
    progressButtons.forEach(btn => {
        btn.addEventListener('click', (evt) => {
            if (disabled) {
                evt.preventDefault();
            }
        });
    });
    const register = () => {
        if (!timeout) {
            timeout = window.setTimeout(checkPlayback, sleepMS);
        }
    };
    const checkPlayback = () => {
        Array.prototype.forEach.apply(videos, [(video) => {
            if (video.currentTime >= video.duration - 5) {
                disabled = false;
                progressButtons.forEach(btn => {
                    btn.className = 'button button--progress button--visible'
                });
                progressInputs.forEach(input => {
                    input.className = 'checkbox--progress progress--enabled';
                    input.disabled = false;
                });
            } else {
                disabled = true;
                progressButtons.forEach(btn => {
                    btn.className = 'button button--progress button--disabled'
                });
                progressInputs.forEach(input => {
                    input.className = 'checkbox--progress progress--disabled';
                    input.disabled = 'disabled';
                });
            }
        }]);
        timeout = window.setTimeout(checkPlayback, sleepMS);
    };
    const cancel = () => {
        if (timeout) {
            window.clearTimeout(timeout);
            timeout = null;
        }
    }

    document.addEventListener('visibilitychange', (evt) => {
        if (evt.target.visibilityState === 'visible') {
            register();
        } else {
            cancel();
        }
    });
    register();
}

overlayDeadline();
registerAddUserApp();
registerVideoHandlers();

document.addEventListener("click", function(evt) {
    if (evt.target.className === "action-print") {
        evt.preventDefault();
        window.print();
    }
});
