<template>
<div class="add-user-app">
    <div class="add-user-app__overlay" v-show="dialogVisible" @click="close()"></div>
    <div class="add-user-app__dialog" v-if="dialogVisible">
        <h2>Benutzer hinzufügen</h2>
        <p class="alert alert--error" v-if="typeof error === 'string'">
            {{ error }}
        </p>
        <form method="post" class="form" @submit.prevent="submit()">
            <div class="grid-x grid-padding-x">
                <div class="control-group cell medium-6" :class="hasError('first_name') ? 'control-group--error' : ''">
                    <label>Vorname:</label>
                    <div class="control">
                        <input type="text" v-focus ref="firstname" :disabled="loading" />
                        <span class="help-inline help-inline--error" v-if="hasError('first_name')">{{error.first_name[0]}}</span>
                    </div>
                </div>
                <div class="control-group cell medium-6" :class="hasError('last_name') ? 'control-group--error' : ''">
                    <label>Nachname:</label>
                    <div class="control">
                        <input type="text" ref="lastname" :disabled="loading" />
                        <span class="help-inline help-inline--error" v-if="hasError('last_name')">{{error.last_name[0]}}</span>
                    </div>
                </div>
            </div>
            <div class="grid-x grid-padding-x">
                <div class="control-group cell medium-6" :class="hasError('name_prefix') ? 'control-group--error' : ''">
                    <label>Namenspräfix:</label>
                    <div class="control">
                        <input type="text" v-focus ref="nameprefix" :disabled="loading" />
                        <span class="help-inline help-inline--error" v-if="hasError('name_prefix')">{{error.first_name[0]}}</span>
                    </div>
                </div>
                <div class="control-group cell medium-6" :class="hasError('name_suffix') ? 'control-group--error' : ''">
                    <label>Namenssuffix:</label>
                    <div class="control">
                        <input type="text" ref="namesuffix" :disabled="loading" />
                        <span class="help-inline help-inline--error" v-if="hasError('name_suffix')">{{error.last_name[0]}}</span>
                    </div>
                </div>
            </div>
            <div class="grid-x grid-padding-x">
                <div class="control-group cell medium-12" :class="hasError('username') ? 'control-group--error' : ''">
                    <label>E-Mail:</label>
                    <div class="control">
                        <input type="text" ref="email" :disabled="loading" />
                        <span class="help-inline help-inline--error" v-if="hasError('username')">{{error.username[0]}}</span>
                    </div>
                </div>
            </div>
            <div class="form__actions">
                <button type="submit" class="button button--primary"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> Benutzer hinzufügen</button>
                <a href="#" @click="close()" class="button button--cancel">Abbrechen</a>
            </div>
        </form>
    </div>
    <a href="#" class="button button--primary" @click="showDialog()">Benutzer hinzufügen</a>
    <a href="csv-import/" class="button button--primary">Benutzer via CSV importieren</a>
</div>
</template>
<style>
.add-user-app__dialog {
    width: 80%;
    max-width: 500px;
    margin: auto;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    background: #FFF;
    border: 2px solid #DDD;
    box-shadow: 10px 5px 5px #AAA;
    padding: 10px;
}
.add-user-app__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #FFF;
    opacity: 0.8;
}
</style>
<script>
import axios from 'axios';

export default {
    props: ['clientID'],
    data() {
        return {
            dialogVisible: false,
            error: null,
            loading: false
        };
    },
    methods: {
        hasError(fieldName) {
            return this.error && this.error[fieldName] && this.error[fieldName].length;
        },
        showDialog() {
            this.dialogVisible = true;
        },
        close() {
            if (this.loading) {
                return;
            }
            this.dialogVisible = false;
            this.loading = false;
        },
        async submit() {
            var data = new URLSearchParams();
            data.append('first_name', this.$refs.firstname.value);
            data.append('last_name', this.$refs.lastname.value);
            data.append('name_prefix', this.$refs.nameprefix.value);
            data.append('name_suffix', this.$refs.namesuffix.value);
            data.append('email', this.$refs.email.value);
            this.loading = true;
            try {
                const resp = await axios.post(`/api/v1/clients/${this.clientID}/add/`, data);
                window.location.reload();
            } catch (e) {
                if (typeof e.response.data === 'undefined') {
                    this.error = 'Failed to create user. Please contact a system administrator.';
                    return;
                }
                this.error = e.response.data;
            } finally {
                this.loading = false;
                this.$forceUpdate();
            }
        }
    }
}
</script>
